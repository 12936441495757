import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import services2 from "../src/assets/interest.jpg"
import "./Menu1.css";

const InterestRates = () => {
  const interestRates = [
    { duration: "30 days to 45 days", rate: "5.25%" },
    { duration: "46 days to 90 days", rate: "5.75%" },
    { duration: "91 days to 179 days", rate: "6.75%" },
    { duration: "180 days to 364 days", rate: "7.50%" },
    { duration: "One year", rate: "8.40%" },
    { duration: "13 months to 60 months", rate: "7.75%" },
    { duration: "61 months to 120 months", rate: "7.50%" },
    { duration: "Nithyanidhi (Pigmy Account)", rate: "3%" },
    { duration: "Savings Account", rate: "3%" },
    { duration: "RD interest (1 year)", rate: "8.40%" },
    { duration: "RD interest (Above 1 year to 5 years)", rate: "7.75%" },
    { duration: " RD interest (Above 5 year to 10 years)", rate: "7.50%" },
    
  ];

  return (
    <div style={{  background: "linear-gradient(90deg, #fff, #cb71ca)",}}>
        <Navbar/>
        <div className="small-container">
    {/* Sidebar */}
    <div className="small-sidebar">
      {/* Top wave */}
      <div className="sidebar-wave-top"></div>
      <ul>
      <li>
  <a href="presidents_message">President's Message</a>
</li>
        <li><a href="#">Annual Report</a></li>
        <li><a href="interest">Interest Rate</a></li>
        <li><a href="loans">Loans</a></li>
        <li><a href="awards">Awards</a></li>
      </ul>
      {/* Bottom wave */}
      <div className="sidebar-wave"></div>
    </div>

    {/* Content Section */}
    <div className="small-content">
      <div className="image-wrapper">
      <img src={services2} alt="About Us" style={{ width: "1000px", height: "340px", objectFit: "cover" }} />

       
      </div>
    </div>
    
  </div>
    <div
      style={{
        maxWidth: "800px",
        margin: "20px auto",
        padding: "20px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#fff",
       
      }}
    >
      <h2
        style={{
          textAlign: "center",
          backgroundColor: "#880e4f",
          color: "#fff",
          padding: "10px 0",
          borderRadius: "8px 8px 0 0",
        }}
      >
        Attractive Interest Rates on Deposits
      </h2>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginTop: "10px",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                textAlign: "left",
                padding: "10px",
                backgroundColor: "#f5f5f5",
                borderBottom: "2px solid #ddd",
              }}
            >
              Duration
            </th>
            <th
              style={{
                textAlign: "right",
                padding: "10px",
                backgroundColor: "#f5f5f5",
                borderBottom: "2px solid #ddd",
              }}
            >
              Interest Rate
            </th>
          </tr>
        </thead>
        <tbody>
          {interestRates.map((item, index) => (
            <tr key={index} style={{textAlign:"justify"}}>
              <td
                style={{
                  padding: "10px",
                  backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {item.duration}
              </td>
              <td
                style={{
                  textAlign: "right",
                  padding: "10px",
                  backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {item.rate}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p
        style={{
          marginTop: "20px",
          backgroundColor: "#fce4ec",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #f8bbd0",
        }}
      >
        <strong>Note:</strong> Special 0.4% extra interest for 1 year and above
        deposits for Senior citizens, Physically challenged, Widows, Soldiers,
        and other institutions.
      </p>
    </div>
    <Footer/>
    </div>
  );
};

export default InterestRates;
