import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./MapComponent.css";
// Optional for additional styling

// Fixing default Marker icon issues in Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
});

export default function MapComponent() {
  const mapCenter = [12.9165, 75.6768]; // Coordinates for Puttur, Karnataka

  const locations = [
    { name: "Head Office", position: [12.7537072, 75.2143534] },
    { name: "Administrative Office", position: [12.4672336, 75.12254232] },
    { name: "Sullia", position: [12.3329, 75.2321] },
    { name: "Puttur", position: [12.4672336, 75.12254232] },
    { name: "B.C. Road", position: [12.8674, 75.4278] },
    { name: "Mangalore", position: [12.9141, 74.8560] },
    { name: "Puttur", position: [12.4672336, 75.12254232] },
    { name: "Sheshadripuram Bengaluru", position: [12.9937, 77.5836] },
    {name: "Belthangady", position: [12.9887,75.2725]},
    {name:"Shirva", position: [13.2163,74.8258]},
    {name: " Jayanagar", position:[12.9205,77.5920]},
    {name: "Bellare", position: [12.40,75.22]},
    {name: "Kadaba", position: [12.4400, 75.28]},
    {name: "Vitla", position: [12.7636,75.1016]},
    {name:"Virajpet", position: [12.12,75.48]},
    {name: "T. Dasarahalli", position: [13.0491,77.5137]},
    {name: "Surathkal", position: [12.9951, 74.8094]},
    {name:"Machohalli", position: [13.0068,77.4532]},
    // {name:"Gurupura Kaikamba", position: [12.52,74.50]},
    {name:"Madantyar", position:[12.9887,75.2725]},
    {name: "Mudipu", position:[12.8029,74.9642]}
  ];

  return (
    <div className="map-container">
    {/* List of Locations */}
    {/* <div className="location-list">
      <h3>Locations</h3>
      <ul>
        {locations.map((location, index) => (
          <li key={index}>{location.name}</li>
        ))}
      </ul>
    </div> */}

    {/* Map */}
    <div className="map-view">
      <MapContainer center={mapCenter} zoom={10} style={{ height: "100%", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {locations.map((location, index) => (
          <Marker key={index} position={location.position}>
            <Popup>{location.name}</Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  </div>
  );
}
