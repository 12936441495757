import React from 'react';
import "./Services.css";
import Slider from "react-slick";
import slide1 from "../src/assets/img1.jpg";
import slide2 from "../src/assets/img2.png";
import slide3 from "../src/assets/img3.png";
import slide4 from "../src/assets/img4.jpg";

export default function Services() {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1400,
    };
  return (
    <div>
    <div className="app-card">
      <div className="card-containers">
        <div className="cards">
          <h2 style={{color:"#630563"}}>History</h2>
          <p>
            Cooperative is a product of a decade effort by the young co-operators
            of South Canara. The economic empowerment through cooperation is the motto.
          </p>
          <a className="read-more-btn" href="about1">Read More</a>
        </div>
     
        <div className="cards">
          <h2 style={{color:"#630563"}}>Services</h2>
          <ul>
            <li><strong>Vehicle Loans:</strong> We provide New vehicle and Old vehicle Loans.</li>
            <li><strong>Jewel Loans:</strong> If you need funds for any purpose, our consignees jewel loan scheme is ideal.</li>
          </ul>
          <a className="read-more-btn" href='services'>Read More</a>
        </div>
        <div className="cards">
          <h2 style={{color:"#630563"}}>Sahakari Services and Offers</h2>
          <p>
          This support has given a tremendous confidence to serve you better in the future days.
Sahakari's Services and Offers.
          </p>
          
        </div>
      </div>
      
    </div>
    <div style={{ width: "100%", textAlign: "center", margin: 0, padding: 0 }}>
                {/* <h2 style={{ margin: "20px 0" }}>Housing Loan</h2> */}
                <Slider {...sliderSettings}>
    <div>
        <img
            src={slide1}
            alt="Slide 1"
            style={{
                width: "90%",
                height: "200px",
                objectFit: "contain",
                margin: "0 auto",
                display: "block",
            }}
        />
    </div>
    <div>
        <img
            src={slide2}
            alt="Slide 2"
            style={{
                width: "90%",
                height: "200px",
                objectFit: "contain",
                margin: "0 auto",
                display: "block",
            }}
        />
    </div>
    <div>
        <img
            src={slide3}
            alt="Slide 3"
            style={{
                width: "90%",
                height: "200px",
                objectFit: "contain",
                margin: "0 auto",
                display: "block",
            }}
        />
    </div>
    <div>
        <img
            src={slide4}
            alt="Slide 4"
            style={{
                width: "90%",
                height: "200px",
                objectFit: "contain",
                margin: "0 auto",
                display: "block",
            }}
        />
    </div>
</Slider>


                </div>
    </div>
  )
}
