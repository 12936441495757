import React from "react";
import "./AboutUs.css";
import trustImage from "../src/assets/CharitableTrust1.jpg"; // Replace with the actual image path

const AboutUs = () => {
  return (

    <div className="about-us-container">
       
      {/* Text Section */}
      <div className="about-us-text">
        
        <h2  >About Trust</h2>
        <p>
          Shri Saraswathi Charitable Trust (R) is the subsidiary institution of
          Shri Saraswathi Credit Souharda Sahakari Ltd, founded in accordance
          with Section 15 of Karnataka Souharda Sahakari Act 1997.
        </p>
        <p>
          The prime motto of the Trust is social commitment, i.e., concern for
          community, which is one of the cooperative principles.
        </p>
        <a className="read-more-btn" href="subsidiary">Read More</a>
      </div>

      {/* Image Section */}
      <div className="about-us-image">
        <img src={trustImage} alt="Trust" />
      </div>
    </div>
  );
};

export default AboutUs;
