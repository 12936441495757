import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import financial from "../src/assets/Financial.jpg";

const financialDetails = [
  { year: "2016-17", members: "24444", shareCapital: "519.65", deposits: "14404.94", loans: "12334.99", reserves: "748.46", netProfit: "275.89", dividend: "13" },
  { year: "2017-18", members: "27192", shareCapital: "659.48", deposits: "16117.17", loans: "11136.61", reserves: "957.79", netProfit: "304.03", dividend: "13" },
  { year: "2018-19", members: "30371", shareCapital: "813.28", deposits: "17827.68", loans: "13010.99", reserves: "1149.99", netProfit: "351.01", dividend: "13" },
  { year: "2019-20", members: "33118", shareCapital: "977.30", deposits: "19672.32", loans: "15578.22", reserves: "1370.21", netProfit: "195.86", dividend: "8.5" },
  { year: "2020-21", members: "34784", shareCapital: "951.07", deposits: "21009.22", loans: "15873.65", reserves: "1471.55", netProfit: "375.05", dividend: "10" },
  { year: "2021-22", members: "36353", shareCapital: "964.92", deposits: "22687.17", loans: "17910.48", reserves: "1727.83", netProfit: "447.86", dividend: "10" },
  { year: "2022-23", members: "40075", shareCapital: "981.65", deposits: "25656.30", loans: "22797.62", reserves: "2043.30", netProfit: "515.10", dividend: "12" },
  { year: "2023-24", members: "45077", shareCapital: "975.47", deposits: "31629.71", loans: "28659.76", reserves: "2405.57", netProfit: "519.27", dividend: "12" },
];

const FinancialDetailsCard = () => {
  return (
    <div>
      <Navbar />
      <div className="small-container">
    {/* Sidebar */}
    <div className="small-sidebar">
      {/* Top wave */}
      <div className="sidebar-wave-top"></div>
      <ul>
      <li>
  <a href="presidents_message">President's Message</a>
</li>
        <li><a href="#">Annual Report</a></li>
        <li><a href="interest">Interest Rate</a></li>
        <li><a href="loans">Loans</a></li>
        <li><a href="awards">Awards</a></li>
      </ul>
      {/* Bottom wave */}
      <div className="sidebar-wave"></div>
    </div>

    {/* Content Section */}
    <div className="small-content">
      <div className="image-wrapper">
      <img src={financial} alt="About Us" style={{ width: "1000px", height: "340px", objectFit: "cover" }} />

       
      </div>
    </div>
    
  </div>
      <div
        style={{
          background: "linear-gradient(90deg, #fff, #cb71ca)",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
         
        }}
      >
        <div>
        <h1
            style={{
              textAlign: "left",
              marginBottom: "20px",
              fontSize: "22px",
              fontWeight: "bold",
              color: "#700d69",
            }}
          >
          Financial Details
          </h1>

        <div
          style={{
            width: "100%", // Full width of the parent container
            maxWidth: "1200px", // Maximum width for larger screens
            minWidth: "300px", // Minimum width for smaller screens
            padding: "20px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            fontFamily: "Arial, sans-serif",
            backgroundColor: "#fff",
          }}
        >
          <h5
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontSize: "22px",
              fontWeight: "bold",
              color: "#700d69",
            }}
          >
            THE BIRD EYE VIEW OF PROGRESS OF THE SAHAKARI. Progress Details report as on 31-03-2024(2023-24) Rupees in lakhs.
          </h5>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              fontSize: "14px",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#700d69", color: "#fff", textAlign: "left" }}>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>Year</th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>Members</th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>Share Capital</th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>Deposits</th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>Loans</th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>Reserves</th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>Net Profit</th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>Dividend (%)</th>
              </tr>
            </thead>
            <tbody>
              {financialDetails.map((item, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff" }}>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.year}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.members}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.shareCapital}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.deposits}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.loans}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.reserves}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.netProfit}</td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.dividend}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FinancialDetailsCard;
