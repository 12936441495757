import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import service from "../src/assets/interest.jpg"

const LoansCard = () => {
  const loans = [
    "Jewel Loan",
    "Gold Loan",
    "Vehicle Loan",
    "Mortgage Loan",
    "Builders Loan",
    "Housing Loan",
    "Partially Secured Loan",
    "Fully Secured Loan",
    "Over Draft Loan",
  ];

  return (
    <div style={{ background: "linear-gradient(90deg, #fff, #cb71ca)",}}>
        <Navbar/>
         <div className="small-container">
    {/* Sidebar */}
    <div className="small-sidebar">
      {/* Top wave */}
      <div className="sidebar-wave-top"></div>
      <ul>
      <li>
  <a href="presidents_message">President's Message</a>
</li>
        <li><a href="#">Annual Report</a></li>
        <li><a href="interest">Interest Rate</a></li>
        <li><a href="loans">Loans</a></li>
        <li><a href="awards">Awards</a></li>
      </ul>
      {/* Bottom wave */}
      <div className="sidebar-wave"></div>
    </div>

    {/* Content Section */}
    <div className="small-content">
      <div className="image-wrapper">
      <img src={service} alt="About Us" style={{ width: "1000px", height: "340px", objectFit: "cover" }} />

       
      </div>
    </div>
    
  </div>
  
    <div
      style={{
        maxWidth: "500px",
        margin: "20px auto",
        padding: "20px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#fff",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          background: "linear-gradient(90deg, #fff, #cb71ca)",
          color: "#700d69",
          padding: "10px",
          borderRadius: "8px 8px 0 0",
        }}
      >
        Loans
      </h2>
      <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
        {loans.map((loan, index) => (
          <li
            key={index}
            style={{
              padding: "10px",
              borderBottom: "1px solid #eee",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{loan}</span>
          </li>
        ))}
      </ul>
    </div>
    <Footer/>
    </div>
  );
};

export default LoansCard;
