
import './App.css';

// import Navbar from './Navbar';
// import Navbar1 from './Navbar1';
import { Routes, Route } from "react-router-dom";
import Home from './Home';
import AboutUs1 from './AboutUs1';
import Services1 from './Services1';
import PresidentsMessage from './PresidentsMessage';
import AwardPage from './AwardPage';
import Subsidiary from './Subsidiary';
import InterestRates from './InterestRates';
import LoansCard from './LoansCard';
import Directors from './Directors';
import FinancialDetailsCard from './FinancialDetailsCard';
import Contact from './Contact';


function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/manu" element={<Manu />} /> */}
        <Route path="/" element={<Home />}></Route>
        <Route path="about1" element={<AboutUs1 />}></Route>
        <Route path="services1" element={<Services1 />}></Route>
        <Route path="presidents_message" element={<PresidentsMessage />}></Route>
        <Route path="awards" element={<AwardPage />}></Route>
        <Route path="subsidiary1" element={<Subsidiary />}></Route>
        <Route path="interest" element={<InterestRates />}></Route>
        <Route path="loans" element={<LoansCard />}></Route>
        <Route path="directors" element={<Directors />}></Route>
        <Route path="financial1" element={<FinancialDetailsCard />}></Route>
        <Route path="contact1" element={<Contact />}></Route>
      
      </Routes>
      <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
          integrity="sha256-o9N1jW6leA34GLFjMIEtPvFWmi3Zn7Wc6CiHTND8M2o="
          crossorigin=""
        />

      {/* <Navbar1/> */}
      {/* <Navbar/> */}
    </div>
  );
}

export default App;
