import React from 'react'
import Navbar from './Navbar'
import AboutMenu1 from './AboutMenu1'

export default function AboutUs1() {
  return (
    <div>
      <Navbar/>
      <AboutMenu1/>
    </div>
  )
}
