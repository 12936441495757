import React from "react";

const styles = {
  footer: {
    width: "100%",
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
    padding: "15px 0",
    // position: "fixed", 
    bottom: 0,
    left: 0,
    zIndex: 1000,
    fontSize: "14px",
  },
  link: {
    color: "#00aaff", // Highlighted link color
    textDecoration: "none",
    fontWeight: "bold",
  },
};

export default function Footer() {
  return (
    <footer style={styles.footer}>
      Powered By{" "}
      <a
        href="https://www.onevega.com"
        target="_blank"
        rel="noopener noreferrer"
        style={styles.link}
      >
        ONEVEGA Systems (India) Pvt. Ltd.
      </a>
    </footer>
  );
}
