import React from "react";
import presidentImage from "../src/assets/SS_President.png";
import ssp1 from "../src/assets/ssp.png";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./Menu1.css";

const styles = {
  card: {
    display: "flex",
    alignItems: "flex-start",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    background: "linear-gradient(90deg, #fff, #cb71ca)",
    maxWidth: "980px",
    margin: "20px auto",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
    marginTop: "10px"
  },
  imageContainer: {
    marginRight: "20px",
    flexShrink: 0,
  },
  image: {
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  content: {
    flexGrow: 1,
  },
  heading: {
    marginBottom: "10px",
    color: "#700d69",// Purple color
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  text: {
    color: "#333",
    fontSize: "1rem",
      textAlign: "justify",
    
  
  },
};

const PresidentsMessage = () => {
  return (
    <div>
        <Navbar/>
        <div className="small-container">
    {/* Sidebar */}
    <div className="small-sidebar">
      {/* Top wave */}
      <div className="sidebar-wave-top"></div>
      <ul>
      <li>
  <a href="presidents_message">President's Message</a>
</li>
        <li><a href="#">Annual Report</a></li>
        <li><a href="interest">Interest Rate</a></li>
        <li><a href="loans">Loans</a></li>
        <li><a href="awards">Awards</a></li>
      </ul>
      {/* Bottom wave */}
      <div className="sidebar-wave"></div>
    </div>

    {/* Content Section */}
    <div className="small-content">
      <div className="image-wrapper">
      <img src={ssp1} alt="About Us" style={{ width: "1000px", height: "340px", objectFit: "cover" }} />

       
      </div>
    </div>
    
  </div>
    <div style={styles.card}>
      {/* Image Section */}
      <div style={styles.imageContainer}>
        <img
          src={presidentImage}
          alt="President"
          style={styles.image}
        />
      </div>

      {/* Content Section */}
      <div style={styles.content}>
        <h2 style={styles.heading}>President's Message</h2>
        <p style={styles.text}>
          It is with great pleasure that I address this message to you on behalf
          of Shri Saraswathi Credit Souharda Sahakari Ltd. Puttur D. K.
        </p>
        <p style={styles.text}>
          The Co-Operative movement is having historical background in India. It
          is the root of Indian life. Co-Operatives are for all class of people.
          Further, it was always champion of the disadvantaged in the society.
          Today credit societies play an important role in economic as well as
          social development of the country. Apart from that they are promoting
          development and to fulfill the concept of financial inclusion.
        </p>
        <p style={styles.text}>
          Shri Saraswathi Credit Souharda Sahakari strongly believes in
          Co-Operative principles for the welfare of its members and strives to
          render various financial services under one roof and to become
          financial super market, where in all financial needs of the people are
          fulfilled. Thanks to our large number of members for their continued
          support for the growth of the Sahakari. We expect the same in future
          too.
        </p>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default PresidentsMessage;
