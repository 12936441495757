import React from 'react'
import "./Menu1.css";
import about from "../src/assets/about_1.jpg";
import Footer from './Footer';


export default function AboutMenu1() {
  return (
    <div>
    <div className="small-container">
    {/* Sidebar */}
    <div className="small-sidebar">
      {/* Top wave */}
      <div className="sidebar-wave-top"></div>
      <ul>
      <li>
  <a href="presidents_message">President's Message</a>
</li>
        <li><a href="#">Annual Report</a></li>
        <li><a href="interest">Interest Rate</a></li>
        <li><a href="loans">Loans</a></li>
        <li><a href="awards">Awards</a></li>
      </ul>
      {/* Bottom wave */}
      <div className="sidebar-wave"></div>
    </div>

    {/* Content Section */}
    <div className="small-content">
      <div className="image-wrapper">
        <img src={about} alt="About Us" className="small-content-image" />
       
      </div>
    </div>
    
  </div>
  <div class="history-card">
  <h3 class="history-title">Vision</h3>
  <p class="history-texts">
  " To be a premier Co-operative Society, to provide Financial solutions through Co-operative principles for enriching economic and social development."<br></br>
  
  </p>
  <p>
  " To emerge as a model cooperative by adopting the best standards of customer service quality along with professionalism in the field of co-operation."
  </p>
  <h3 class="history-title">Mission</h3>
  <p class="history-texts">
  " Strives for excellence in serving our members and customers by way of good professionalism, technology and good work culture."
  
  </p>
</div>

<div class="history-card">
  <h3 class="history-title">Core values</h3>
  <p class="history-texts">
  Good Principles, highest ethics and norms in conducting business.
  
  </p>
 
</div>
<div class="history-card">
  <h3 class="history-title">History of Sahakari</h3>
  <p class="history-text">
  Established on September 2, 2001 at Puttur in Dakshina Kannada district, the home town of the banking sector, the co-operative was registered as the 9th credit Souharda co-operative in the state and the first in Dakshina Kannada district under the Karnataka Souharda Sahakari Act, 1997. The cooperative started with the slogan "Economic Empowerment through Co-operation" with the objective of providing financial security to the deposits of the members and financial support to the needs of the members in an adverse environment where the people have lost confidence due to the sharp fall in the prices of arecanut and rubber, the main crops that determine the economy of Dakshina Kannada district, and the collapse of some financial institutions in the district.
  </p>
</div>
<Footer/>
  </div>
  )
}
