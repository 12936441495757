import React from "react";
import award from "../src/assets/award2.jpg"
import awards from "../src/assets/award1.jpg";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./Menu1.css";

const AwardPage = () => {
   
  return (
    <div>
        <Navbar/>
        <div className="small-container">
    {/* Sidebar */}
    <div className="small-sidebar">
      {/* Top wave */}
      <div className="sidebar-wave-top"></div>
      <ul>
      <li>
  <a href="presidents_message">President's Message</a>
</li>
        <li><a href="#">Annual Report</a></li>
        <li><a href="interest">Interest Rate</a></li>
        <li><a href="loans">Loans</a></li>
        <li><a href="awards">Awards</a></li>
      </ul>
      {/* Bottom wave */}
      <div className="sidebar-wave"></div>
    </div>

    {/* Content Section */}
    <div className="small-content">
      <div className="image-wrapper">
        <img src={awards} alt="About Us" style={{ width: "1000px", height: "340px", objectFit: "cover" }} />
       
      </div>
    </div>
    
  </div>
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
     
      <div
        style={{
          maxWidth: "1190px",
          background: "linear-gradient(90deg, #fff, #cb71ca)",
          margin: "20px auto",
          border: "1px solid #ddd",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
      >
        <div style={{ padding: "20px" }}>
          <h2
            style={{
     
              color: "#700d69",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "15px",
            }}
          >
           Awards
          </h2>
          <p style={{ textAlign: "justify", lineHeight: "1.6", fontSize: "1rem" }}>
            Our Souharda is recognized by Karnataka State Souharda Federal Cooperative
            Limited as the best Souharda among Souharda Cooperatives of Mysore Division.
            We thank all Members for continuous support for winning this award.
          </p>
        </div>
        <div
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px",
  }}
>
  <img
    src={award} // Replace with your image URL or import the image
    alt="Award Ceremony"
    style={{
      width: "700px",
      height: "auto",
      display: "block",
    }}
  />
</div>

        <div style={{ padding: "20px" }}>
          <p style={{ textAlign: "justify", lineHeight: "1.6", fontSize: "1rem" }}>
          Our President Mr. S R Sathischandra, Director Mr. Sunil Borkar and General Manager Mr. Vasanth Received this Award in 60th All India Co-operative saptah -2013 held at T. Chennayya hall Kolar District. Co-operative minister Mr. H. Mahadeva Prasad, Mr. G T Devegowda President of Karnataka State co-operative Mahamandala, Mr K. R. Ramesh Kumar Ex. President of Karnataka Legislative Assembly, Mr. Gurunath Jyanthikar President of Karnataka State Souharda Federal Co-operative were present in this Function.
          </p>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default AwardPage;
