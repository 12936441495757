import React, { useEffect, useState } from "react";
import image1 from "../src/directors/Chandrashekhar.png";
import image2 from "../src/directors/DevakiK.png";
import image3 from "../src/directors/Deviprasad.png";
import image4 from "../src/directors/HarishBorkar.png";
import image5 from "../src/directors/HemanthKumarKR.png";
import image6 from "../src/directors/JayaramP.png";
import image7 from "../src/directors/Prabhu.png";
import image8 from "../src/directors/Prakashchandra.png";
import image9 from "../src/directors/RajagopalaB.png";
import image10 from "../src/directors/RameshchandraM.png";
import image11 from "../src/directors/SaraswathiN.png";
import image12 from "../src/directors/Sathischandra.png";
import image13 from "../src/directors/UmeshPrabhuK.png";
import ssp1 from "../src/assets/ssp.png";
import image14 from "../src/directors/Vasanth.jpg" // Example additional image
import Navbar from "./Navbar";
import Footer from "./Footer";

const directorsData = [
    {
        name: "Sri S R Sathischandra",
        title: "President",
        address: "Devisadana, Punacha, Bantwal.",
        
        mobile: "+91-9448696108",
        image: image12,
      },
      {
        name: "Mr Umesh Prabhu K",
        title: "Vice President",
        
        address: "S/o Appayya Prabhu,Kuntihithlu House, Doddathota Post,Sullia Tq D.K-574248.",
        mobile: "+91-9480016905",
        image: image13,
      },
  {
    name: "Sri Deviprasad K.",
    title: "Director",
    address: "S/o Srinivasa Sharma K., Sharma Farms, Kallaje House, Punacha Post, Village, Bantwal Taluk D. K.",
    mobile: "+91-9886652495",
    image: image3,
  },
  {
    name: "Mr Jayaram P",
    title: "Director",
    address: "S/o Gopalkrishna, 3rd Cross Valencia, Veramma Compound, Sootarpete, Mangalore.",
    mobile: "+91-9845100852",
    image: image6,
  },
  {
    name: "Mr Harish Borkar K",
    title: "Director",
    address: "S/o Gopala Borkar, #126, Kattalakana House, Nidpalli Post, Village, Puttur Taluk D. K. - 574259",
    mobile: "+91-9449452099",
    image: image4,
  },
  {
    name: "Mr Prakashchandra",
    title: "Director",
    address: "S/o Ananthapadmanabha Nayak,Panjigaru House, Kalanja Post and Village,Sullia Tq, D.K-574212.",
    mobile: "+91-9632275452",
    image: image8,
  },
  {
    name: "Mr Hemanth Kumar K R",
    title: "Director",
    address: "S/o Ramakrishna Nayak,Kandadka House, Duggaladka Post,Sullia Kasaba Village, Sullia Tq, D.K-574239.",
    mobile: "+91-9449569177",
    image: image5,
  },
  {
    name: "Mr Rameshchandra M",
    title: "Director",
    address: "S/o Ananda Nayak,Mundakochi House,Ariyadka Post and Village, Puttur Tq. D.K.",
    mobile: "+91-9480056274",
    image: image10,
  },
  {
    name: "Mr Rajagopala B",
    title: "Director",
    address: "S/o Mariyappa Nayak,D.No. 3/212, Naduvadka House,Irde Post and Village, Puttur Tq, D.K.",
    mobile: "+91-9449101046",
    image: image9,
  },
  {
    name: "Mrs Sarawathi N",
    title: "Director",
    address: "W/o Harishchandra Nayak,Natekallu House, Vitla Mudnoor Village,Kambalabettu Post, Bantwal Tq, D.K.",
    mobile: "+91-9448596771",
    image: image11,
  },
  {
    name: "Mrs Devaki K",
    title: "Director",
    address: "W/o Harish Nayak,Santyar Balakka House, Aryapu Village,Kaikara Post, Puttur Tq, D.K.",
    mobile: "+91-9448596771",
    image: image2,
  },
  {
    name: "Sri Vasanth A",
    title: "General Manager",
    address: "Darbe, Puttur-574202. Dakshina Kannada, Karnataka, India.",
    mobile: "+91-9449773579",
    image: image14,
  },
  {
    name: " Mr. Vedavyasa K ",
    title: "Professional director",
    address: "S/o Divakara Bhat K,Sthuthi, #6/1, Kamath Layout, Forest Gate,Machohalli Magadi Main Road,Bapa Village and Post, Bangalore – 560091.",
    mobile: "+91-9980129663",
   
  },
  {
    name: " Mr. B Vasanth Shankar  ",
    title: "Professional director",
    address: "S/o B Shankara Narayana,#2-3-5-105C/D-0-1, Thushara House,Neharu Nagara Post,Bairikatte Karmala, Puttur Tq D.K- 574203.",
    mobile: "+91-9379622311, +91-9449801105",
   
  },
  {
    name: "  Mr. Raveesha P ",
    title: "Functional director",
    address: "S/o Jayantha Nayak,Posavalike House,Punacha Post and Village, Bantwal TQ,Dakshina Kannada - 574281.",
    mobile: "+91-9740926212",
   
  },
  {
    name: "  Mr. Shrikantha Rao V D ",
    title: "Functional director",
    address: "S/o V A Dayananda Rao,Ramanagara House, Betoli Post and Village,Virajpet Tq, Kodagu-571218,.",
    mobile: "+91-9686805673",
   
  },
 
 
];

const Directors = () => {
  const [columns, setColumns] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setColumns(4); // 4 columns for large screens
      } else if (window.innerWidth >= 768) {
        setColumns(3); // 3 columns for medium screens
      } else if (window.innerWidth >= 640) {
        setColumns(2); // 2 columns for small screens
      } else {
        setColumns(1); // 1 column for mobile screens
      }
    };

    // Set the grid columns initially
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div >
        <Navbar/>
        <div className="small-container">
    {/* Sidebar */}
    <div className="small-sidebar">
      {/* Top wave */}
      <div className="sidebar-wave-top"></div>
      <ul>
      <li>
  <a href="presidents_message">President's Message</a>
</li>
        <li><a href="#">Annual Report</a></li>
        <li><a href="interest">Interest Rate</a></li>
        <li><a href="loans">Loans</a></li>
        <li><a href="awards">Awards</a></li>
      </ul>
      {/* Bottom wave */}
      <div className="sidebar-wave"></div>
    </div>

    {/* Content Section */}
    <div className="small-content">
      <div className="image-wrapper">
      <img src={ssp1} alt="About Us" style={{ width: "1000px", height: "340px", objectFit: "cover" }} />

       
      </div>
    </div>
    
  </div>
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`, // Dynamic columns based on state
        gap: "20px",
        padding: "20px",
        background: "linear-gradient(90deg, #fff, #cb71ca)",
      }}
    >
      {directorsData.map((director, index) => (
        <div
          key={index}
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            textAlign: "center",
            padding: "20px",
            backgroundColor: "#ffffff",
          }}
        >
          <img
            src={director.image}
            alt={director.name}
            style={{
              width: "100%",
              height: "150px",
              objectFit: "cover",
              marginBottom: "15px",
              borderRadius: "10px", // Slightly rounded edges
            }}
          />
          <h3 style={{ margin: "10px 0", color: "#4a4a4a", fontSize: "18px" }}>{director.name}</h3>
          <p style={{ fontWeight: "bold", margin: "5px 0", fontSize: "16px" }}>{director.title}</p>
          <p style={{ fontSize: "14px", color: "#555", margin: "10px 0" }}>{director.address}</p>
          <p style={{ fontWeight: "bold", color: "#007BFF", fontSize: "14px" }}>{director.mobile}</p>
        </div>
      ))}
    </div>
    <Footer/>
    </div>
  );
};

export default Directors;
