import React from 'react';
import Navbar from './Navbar';
import Slider from "react-slick";
import slide1 from "../src/assets/Saraswathi_1.jpg";
import slide2 from "../src/assets/Saraswathi_2.jpg";
import slide3 from "../src/assets/Saraswathi_3.jpg";
import slide4 from "../src/assets/Saraswathi_4.jpg";
import slide5 from "../src/assets/Saraswathi_5.jpg";
import './DetailsCard.css';
import AboutUs from './AboutUs';
import Services from './Services';
import Footer from './Footer';


export default function Home() {
    const status2024 = {
        title: "Financial Status As On 31-03-2024 (Audited)",
        data: [
          { label: "Members", value: "45,077" },
          { label: "Share Capital", value: "₹9.75 Cr" },
          { label: "Owned Funds", value: "₹37 Cr" },
          { label: "Working Capital", value: "₹355 Cr" },
          { label: "Deposits", value: "₹316 Cr" },
          { label: "Loans", value: "₹287 Cr" },
          { label: "Business", value: "₹603 Cr" },
          { label: "Net Profit", value: "₹5.19 Cr" },
          { label: "G. NPA", value: "3.50%" },
          { label: "N. NPA", value: "1.45%" },
          { label: "Branches", value: "19" },
        ],
      };
    
      const status2025 = {
        title: "Financial Status As On 15-01-2025",
        data: [
          { label: "Deposits", value: "₹345.96 Cr" },
          { label: "Loans", value: "₹327.71 Cr" },
          { label: "Working Capital", value: "₹384.85 Cr" },
          { label: "Share Capital", value: "₹9.80 Cr" },
          { label: "Members", value: "48,579" },
        ],
      };
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1400,
    };

    return (
        <div style={{ overflowX: "hidden" }}> {/* Prevents horizontal scroll */}
            <Navbar />
            <br></br>
            <br></br>
            <div style={{ width: "100%", textAlign: "center", margin: 0, padding: 0, marginTop: "140px" }}>
                {/* <h2 style={{ margin: "20px 0" }}>Housing Loan</h2> */}
                <Slider {...sliderSettings}>
                    <div>
                        <img
                            src={slide1}
                            alt="Slide 1"
                            style={{ width: "100%", height: "auto", display: "block" }}
                        />
                    </div>
                    <div>
                        <img
                            src={slide2}
                            alt="Slide 2"
                            style={{ width: "100%", height: "auto", display: "block" }}
                        />
                    </div>
                    <div>
                        <img
                            src={slide3}
                            alt="Slide 3"
                            style={{ width: "100%", height: "auto", display: "block" }}
                        />
                    </div>
                    <div>
                        <img
                            src={slide4}
                            alt="Slide 4"
                            style={{ width: "100%", height: "auto", display: "block" }}
                        />
                    </div>
                    <div>
                        <img
                            src={slide5}
                            alt="Slide 5"
                            style={{ width: "100%", height: "auto", display: "block" }}
                        />
                    </div>
                </Slider>
                <div className="cards-row">
      {/* First Card */}
      <div className="card card-left">
        <h2>Welcome to Shri Saraswathi Credit Souharda Sahakari Ltd</h2>
        <p>
       <b> Saraswathi Sahakari Sangha,</b> which is running parallel to the evolution of the co-operative sector, is entering its 24th year of public service. Since its inception in 2001, the cooperative society has witnessed major events such as demonetization, implementation of GST, merger of banks, covid crisis, natural calamities, etc. After facing all the tests from time to time, today the Co-operative Society has grown into a model in the field of Souharda co-operative sector and is working to provide satisfactory service to the members under the principle of co-operation.
        </p>
        <p>
        Welcoming you warmly to the<b> Saraswathi Parivar</b>, we are happy to give a brief introduction of the Sangha.
        </p>
      </div>

      {/* Second Card */}
      <div className="card card-right">
       
        <h5>
        Financial Status
      </h5>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
        }}
      >
        {[status2024, status2025].map((status, index) => (
          <div key={index} style={{ border: "1px solid #ddd", padding: "15px" }}>
            <h5
              style={{
                textAlign: "center",
                backgroundColor: "#f5f5f5",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              {status.title}
            </h5>
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {status.data.map((item, i) => (
                <li
                  key={i}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 0",
                    borderBottom: "1px solid #eee",
                  }}
                >
                  <span>{item.label}:</span>
                  <span>{item.value}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      </div>
    </div>
    <AboutUs/>
    <Services/>
            </div>
         
            <Footer/>
        </div>
    );
}
