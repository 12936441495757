import React from 'react'
import Navbar from './Navbar'
// import contact1 from "../assests/contact.jpg"
import contact from "../src/assets/contactus.jpg"
import { Accordion, Card} from 'react-bootstrap';
import MapComponent from './MapComponent';


const imgAboutBaStyle = {
    maxWidth: "920px",
    marginTop: "-359px",
    paddingTop: "50px",
    height: "319px",
    marginLeft: "485px",
};


const imgAboutBadivStyle = {
    marginTop: "-17px",
}

const hrStyle = {
    border: "none",
    borderTop: "2px solid #8e0c56",
    marginTop: "10px",
};

const imgStyle = {
    maxWidth: "450px",
    // marginTop: "-130px",
    marginLeft: "205px",
};

export default function Contact() {
    return (
        <div>
            <Navbar />
            <div>
                <div className="small-container">
                    {/* Sidebar */}
                    <div className="small-sidebar">
                        {/* Top wave */}
                        <div className="sidebar-wave-top"></div>
                        <ul>
                            <li>
                                <a href="presidents_message">President's Message</a>
                            </li>
                            <li><a href="#">Annual Report</a></li>
                            <li><a href="interest">Interest Rate</a></li>
                            <li><a href="loans">Loans</a></li>
                            <li><a href="awards">Awards</a></li>
                        </ul>
                        {/* Bottom wave */}
                        <div className="sidebar-wave"></div>
                    </div>

                    {/* Content Section */}
                    <div className="small-content">
                        <div className="image-wrapper">
                            <img src={contact} alt="About Us" style={{ width: "1000px", height: "340px", objectFit: "cover" }} />


                        </div>
                    </div>

                </div>
                <div style={imgAboutBadivStyle}>
                    <img src={contact} alt="About Us" style={imgAboutBaStyle} />
                </div>
                <div>
                    <hr style={hrStyle} />
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", gap: "20px" }}></div>
                <div className="col-md-8" style={imgStyle}>
                <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Head Office</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong>
        <div>S. S. Sadana Vinayakanagara,</div>
        <div>Darbe, Puttur - 574202,</div>
        <div>Dakshina Kannada, Karnataka, India.</div>
        <div>Ph: 08251-237223.</div>
        <div>Mob: 9739986848</div>
        <div>Email: ho@saraswathisahakari.com</div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> Administrative office</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
      <div>  First floor Radhika plaza,(Mangalamrutha soudha),</div>
<div>MS Road, Puttur.</div>
<div>Karnataka, India-574201.</div>
<div>Ph: 08251 - 237223, 236223.</div>
<div>Mob: 9739986848.</div>
<div>Email: adminho@saraswathisahakari.com.</div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header> Sullia</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Sri Durga Complex,</div>
<div>Car street Sullia,</div>

<div>Dakshina Kannada, Karnataka, India - 574239..</div>
<div>Ph: 08257-234288.</div>
<div>Mob: 9739986843..</div>
<div>Email: sullia@saraswathisahakari.com,
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header> Puttur</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Ground floor Radhika plaza,</div>
<div>MS Road, Puttur.</div>

<div>Karnataka, India-574201.</div>
<div>Ph: 08251 - 236222, 238222.</div>
<div>Mob: 9739986845.</div>
<div>Email: msroad@saraswathisahakari.com,
             share@saraswathisahakari.com.
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header> BC Road</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Ground floor, Padma complex.</div>
<div>BC Road, Bantwal.</div>

<div>Dakshina Kannada, Karnataka, India - 574239..</div>
<div>Ph: 08257-234288.</div>
<div>Mob: 9739986843..</div>
<div>Email:  bcroad@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header> Mangalore</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> 1st floor, Royal chambers.</div>
<div>Near P V S Circle.</div>

<div>Kodialbail, Mangalore - 575003.</div>
<div>Ph: 0824-2422833.</div>
<div>Mob: 9739986840.</div>
<div>Email: mangalore@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header> Puttur</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> S. S. Sadana Vinayakanagara.</div>
<div>Darbe branch, Puttur.</div>

<div>Karnataka, India-574202.</div>
<div>Ph: 08251-237222.</div>
<div>Mob: 9739986842.</div>
<div>Email: darbe@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header> Sheshadripuram Bangalore</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> No: 32, Sri Skanda, Siroor Park Road.</div>
<div>Near Hotel New Krishna Bhavan.</div>

<div>Sheshadripuram, Bangalore-560020.</div>
<div>Ph: 080-23462111.</div>
<div>Mob: 9538996847.</div>
<div>Email: bangalore@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header> Belthangady</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Ist Floor, viva complex.</div>
<div>Main road.</div>

<div>Belthangady - 574214.</div>
<div>Ph: 08256-232373.</div>
<div>Mob: 9739986841</div>
<div>Email: bly@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header> Shirva</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Ist Floor, Hotel Shri Mahadevi Bhavan,</div>
<div>Opp: Vijaya Bank, Main Road,</div>

<div>Shirva Udupi-574116.</div>
<div>Ph: 0820-2554488.</div>
<div>Mob: 9986663787</div>
<div>Email: shirva@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="10">
        <Accordion.Header> Jayanagar Bangalore</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> No.28, Pump House Bus Stop,</div>
<div>Opp: Eastend Main Road,</div>

<div>Jayanagar 9th Block Bangalore - 560069.</div>
<div>Ph: 080-26630854.</div>
<div>Mob: 9739986849.</div>
<div>Email: jayanagar@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="11">
        <Accordion.Header> Bellare</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> 1st Floor, Janatha Complex,</div>
<div>Opp: Main road, Bellare,</div>

<div>Sullia Taluk-574212,</div>
<div>Ph: 08257-271888.</div>
<div>Mob: 9739986860..</div>
<div>Email: bellare@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="12">
        <Accordion.Header> Kadaba</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> 1st Floor, Anugraha Complex,</div>
<div>Opp: Main road, Kadaba -574221,</div>

<div>Puttur Taluq, Dakshina Kannada, Karnataka, India.</div>
<div>Ph: 08251 – 260322</div>
<div>Mob: 7022288210</div>
<div>Email: kadaba@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="13">
        <Accordion.Header> Vittal</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Ground Floor, Radhagopala Complex,</div>
<div>Opp: School road,</div>

<div>Bantwal Taluq, Dakshina Kannada, Karnataka, India.</div>
<div>Ph: 08255 - 238666</div>
<div>Mob: 9739986862</div>
<div>Email: vittal@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="14">
        <Accordion.Header> Virajpet</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>First floor Hafeeza Complex,</div>
<div>Opp: Gonikoppa road, Near Mohan Petrol Pamp,</div>

<div>Virajpet Kodagu Dist - 571218,</div>
<div>Ph:  08274 - 260222</div>
<div>Mob: 7022288786</div>
<div>Email: virajpet@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="15">
        <Accordion.Header> T.Dasarahalli Bangalore</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div># 23, First Floor, V G Complex, Opp. Varun Maruthi Motors,</div>
<div>Opp: Hesaraghatta Main Road, Nagasandra Post,</div>

<div>T. Dasarahalli, Bangalore-560073,</div>
<div>Ph:  080-28394009</div>
<div>Mob: 7022284009</div>
<div>Email:  dasaralli@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="16">
        <Accordion.Header> Surathkal, Mangalore</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>Ground Floor City Pearl, Near Town Panchayath Market,</div>
<div>Opp: Idya Village, </div>

<div>Surathkal, Mangalore D. K. -575014,</div>
<div>Ph:  0824-2983616</div>
<div>Mob: 7022278606</div>
<div>Email:  surathkal@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="17">
        <Accordion.Header> Machohalli, Bangalore</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>First Floor, Rajapura Saraswatha Samaja (R.),</div>
<div>Opp: Kamath Layout, Forest Gate Stop, </div>

<div>Magadi Road, Machohalli,Bangalore-560091,</div>
<div>Ph: 080-29774655</div>
<div>Mob: 9620611588</div>
<div>Email:  machohalli@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="18">
        <Accordion.Header> Gurupura KaiKamba</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>Pompei Matha Commercial Complex, First Floor,</div>
<div>Opp: Bajpe Road Gurupura-Kaikamba,Kandavara Village, </div>

<div>Mangalore Taluk Dk - 574151</div>
<div>Ph: 0824-2951219.</div>
<div>Mob: 7022284008</div>
<div>Email:  gk@saraswathisahakari.com
           
             </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="19">
        <Accordion.Header> Madanthyar</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>D.NO.2-58(5), A.N. Complex, First Floor,</div>
<div>Opp: Near SBI Bank, Main Road, Maladi Village, </div>

<div>Madanthyar Post. Belthangady Tq D.K- 574224</div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="20">
        <Accordion.Header> Mudipu</Accordion.Header>
        <Accordion.Body>
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>"Shruti Towers" Second floor, Mudipu,</div>
<div>Opp: Kurnadu Post and Village Ullal Taluk, </div>

<div>Dakshina Kannada District.</div>
<div>Ph: 08255-260225</div>
<div>Mob: 7019002528</div>
<div>Email:  mdp@saraswathisahakari.com


           
             </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                </div>
                
            </div>
            <div>
                    <hr style={hrStyle} />
                </div>
                <MapComponent/>
        </div>
    )
}
