import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import "./AboutUs.css";
import bank from "../src/assets/bank1.jpg"

export default function Subsidiary() {
  return (
    <div>
        <Navbar/>
       <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
            background: "linear-gradient(90deg, #fff, #cb71ca)",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
          maxWidth: "990px",
          padding: "20px",
          lineHeight: "1.6",
          fontSize: "1rem",
          color: "#333",
          textAlign: "justify",
          marginTop:"160px" // Added justify alignment
        }}
      >
        <h2 style={{ color: "#700d69", textAlign: "left", marginBottom: "20px" }}>
          About Trust
        </h2>
        <p style={{ color:"#000000" }}>
          Shri Saraswathi Charitable Trust is the subsidiary institution of <b>Shri Saraswathi
          Credit Souharda Sahakari Ltd </b> which is founded in accordance with section 15 of
          Karnataka Souharda Sahakari Act 1997. The prime motto of the Trust is social
          commitment, i.e., concern for the community, which is one of the cooperative
          principles.
        </p>
        <p style={{ color:"#000000" }}>
        <b>Shri Saraswathi
        Credit Souharda Sahakari Ltd </b> being a socio-economic entity since
          its inception, it has performed various social activities on different platforms.
          To make these activities systematic and more meaningful, the Trust was founded and
          officially inaugurated on 23.05.2015. The Trust is registered under the Indian
          Trust Act 1882 with Registration Number 500/2014-15.
        </p>
        <p style={{ color:"#000000" }}>
          As a cooperative, <b>Shri Saraswathi
          Credit Souharda Sahakari Ltd </b>serves through
          various social and economic activities for the empowerment of members through
          mutual cooperation to implement and execute the accepted cooperative policy of
          concern for the community. Sahakari consistently extends its commitment towards
          social awareness programs and helps needy members of society.
        </p>
        <p style={{ color:"#000000" }}>
          To empower poor and marginalized people to take advantage of burgeoning global
          opportunities, Shri Saraswathi Charitable Trust aims to adopt the concept of social
          inclusion. It is the process of improving the terms for individuals to participate
          in community and society and encouraging the contribution of all persons to social
          and cultural life.
        </p>
        <h2 style={{ color: "#700d69", textAlign: "left", marginBottom: "20px" }}>
          Contact Us
        </h2>
        <p style={{ marginBottom: "10px" }}>
          <b>The registered office of the Trust is situated at:</b>
        </p>
        <address style={{ fontStyle: "normal", marginBottom: "20px" }}>
          <strong>Sachidananda Seva Sadana,</strong>
          <br />
          Vinayaka Nagara, Darbe,
          <br />
          Puttur - 574 202,
          <br />
          Dakshina Kannada District.
        </address>
        <p>
          <strong>Email:</strong>{" "}
          <a
            href="mailto:trust@saraswathisahakari.com"
            style={{ color: "#6a1b9a", textDecoration: "none" }}
          >
            trust@saraswathisahakari.com
          </a>
        </p>
        <p>
          <strong>Web:</strong>{" "}
          <a
            href="http://www.saraswathisahakari.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#6a1b9a", textDecoration: "none" }}
          >
            www.saraswathisahakari.com
          </a>
        </p>
        <p>
          <strong>Ph No:</strong> 08251-237223
        </p>
        <p>
          <strong>Mob:</strong> 7022284004
        </p>
      </div>
      
      
    </div>
    <div className="about-us-container">
       
       {/* Text Section */}
       <div className="about-us-text">
         
         <h2  >Co-operative Education :</h2>
         <p style={{ color:"#000000" }}>
         The Cooperative Sector, Today, contributes greatly to the GDP of the Country and the Central Government has established a separate Ministry for the Cooperative Sector. To strengthen this sector, education and promotional programmes on the cooperative sector are being conducted continuously. In addition, a Memorandum of Understanding (MoU) has been signed between the Co-operative Society and Vivekananda Mahavidyalaya (Autonomous), Puttur, D.K., for the study of the co-operative sector
         </p>
       
       </div>
 
       {/* Image Section */}
       <div className="about-us-image">
         <img src={bank} alt="Trust" />
       </div>
     </div>
     <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
            background: "linear-gradient(90deg, #fff, #cb71ca)",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
          maxWidth: "1180px",
          padding: "20px",
          lineHeight: "1.6",
          fontSize: "1rem",
          color: "#333",
          textAlign: "justify",
           // Added justify alignment
        }}
      >
       
       <h2 style={{ color: "#6a1b9a", textAlign: "left", marginBottom: "20px" }}>
       Shri Saraswathi Charitable Trust has set forth the below objectives.
        </h2>
        <h3 style={{ color: "#4a148c", marginTop: "20px" }}>Relief of the Poor</h3>
        <ul style={{ marginLeft: "20px" }}>
          <li style={{ color:"#000000" }}>
            Identifying and honoring eminent personalities in fields such as agriculture,
            horticulture, cooperation, economics, sports, arts, commerce, science, education, and
            literature.
          </li>
          <li style={{ color:"#000000" }}>
            Organizing, arranging, and maintaining rehabilitation centers for handicapped, disabled,
            aged, orphans, victims of natural calamities, epidemic diseases, or social evils
            independently or with other organizations/institutions.
          </li>
          <li style={{ color:"#000000" }}>Providing annadasoha, i.e., food for students and needy people.</li>
        </ul>

        <h3 style={{ color: "#4a148c", marginTop: "20px" }}>Education</h3>
        <ul style={{ marginLeft: "20px" }}>
          <li style={{ color:"#000000" }}>
            Establishing, managing, and maintaining educational institutions for pre-primary to
            post-graduation and research in fields like Arts, Commerce, Science, Law, IT, Language,
            Astrology, Ayurveda, Yoga, etc.
          </li>
          <li style={{ color:"#000000" }}>
            Promoting technical education in fields such as Engineering, Polytechnics, Skill
            Development, Communication Skills, Personality Development, and vocational training.
          </li>
          <li style={{ color:"#000000" }}>
            Organizing training programs for competitive exams such as IAS, IRS, IFS, CET, KAS,
            KMAS, etc.
          </li>
        </ul>

        <h3 style={{ color: "#4a148c", marginTop: "20px" }}>Medical Relief</h3>
        <ul style={{ marginLeft: "20px" }}>
          <li style={{ color:"#000000" }}>
            Identifying blood donors, maintaining blood donors' registers, conducting blood donation
            camps, and establishing blood banks.
          </li>
          <li style={{ color:"#000000" }}>Arranging ambulance services and providing health education to the public.</li>
          <li style={{ color:"#000000" }}>Creating awareness about blood, eye, and body donation.</li>
        </ul>

        <h3 style={{ color: "#4a148c", marginTop: "20px" }}>Preservation of Environment</h3>
        <ul style={{ marginLeft: "20px" }}>
          <li style={{ color:"#000000" }}>
            Conducting social awareness programs on preserving the environment, food, energy, water,
            and hygiene.
          </li>
          <li style={{ color:"#000000" }}>Providing information on the proper utilization and preservation of natural resources.</li>
          <li style={{ color:"#000000" }}>Promoting solid waste management and rooftop farming initiatives.</li>
        </ul>
        
        <h2 style={{ color: "#6a1b9a", textAlign: "left", marginBottom: "20px" }}>
          
       Shri Saraswathi Charitable Trust has set forth the below objectives.
        </h2>
       
        <ul style={{ marginLeft: "20px" }}>
          <li style={{ color:"#000000" }}>
          To promote, guide, manage, name, recognize and affiliate “Self Help Groups” or “joint liability group” of Individuals engaged in the fields of Agriculture, Horticulture, Sericulture, Animal Husbandry, Fishing, Bee Keeping, Handicrafts, and Village Industries as an owner or worker for their mutual benefits.
          </li>
          <li style={{ color:"#000000" }}>To organize, promote, guide, manage, recognize and affiliate various units of “Self Help Groups” or “Joint Liability Groups” at Village, Hobli, Taluk, District or State level and form a Federation of Self Help Groups or Joint Liability Groups and conduct or assist in conducting workshop, seminar, exhibition, training, conference, road show etc. activities for the benefits of members or managers of Self Help Groups or Joint Liability Groups and to frame Rules, Sub Rules & regulations for them, and to introduce welfare schemes.</li>
          <li style={{ color:"#000000" }}>To print, publish, and distribute books, periodicals, leaflets, brochures and papers for the promotion of the objects of the Trust and/or “Author of the Trust / Settlor Organization”.</li>
          <li style={{ color:"#000000" }}>To organize and conduct programmes for providing employment to youths on it's own or in collaboration with other agencies or association</li>
          <li style={{ color:"#000000" }}>To organize and conduct programmes for educating public opinion regarding eradication of under employment, poverty and social evils like illiteracy, dowry, casteism, consuming alcohol, drugs, tobacco, gutka, and/or corruption etc....</li>
          <li style={{ color:"#000000" }}>To Associate or have collaboration with other Governmental or Non-Governmental Organizations in conducting charitable activities which are similar to the objects of the Trust.</li>
          <li style={{ color:"#000000" }}>To encourage use of non conventional energy including solar, gober gas, biogas to save electric power and to associate with suppliers of such equipments to provide information and awareness among public.</li>
          <li style={{ color:"#000000" }}>To establish information and facilitation centre to help public regarding various social facilities provided by Government like widow pension, disabilities pension, Bhagyalxmi scheme, Sandhya Suraksha, Sakala, PAN, Aadhar Card, Epic Card, Senior citizen Card, etc...</li>
          <li style={{ color:"#000000" }}>To organize and conduct program for empowering Women, Tribal Groups, Forest Dwellers, Schedule Caste and/or Schedule Tribe or for other backward classes.</li>
          <li style={{ color:"#000000" }}>To arrange, assist and organize community awareness programmes</li>
        </ul>
      
       
         
         
       
      </div>
      
      
    </div>
     
    <Footer/>
    </div>
  )
}
