import React from 'react'
import Navbar from './Navbar'
import "./Menu1.css";
import service1 from "../src/assets/service_1.jpg";

export default function Services1() {
    const styles = {
        container: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
      
          minHeight: "50vh",
        },
        card: {
            background: "linear-gradient(90deg, #fff, #cb71ca)",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          maxWidth: "900px",
          width: "100%",
          
          textAlign: "left",
        },
        header: {
          color: "#800080",
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "15px",
          textAlign: "center",
        },
        list: {
          listStyleType: "none",
          padding: 0,
        },
        listItem: {
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          fontSize: "16px",
          color: "#000000",
        },
        bulletIcon: {
          marginRight: "10px",
          fontSize: "18px",
          color: "#800080",
        },
      };
      
  return (
    <div>
      <Navbar/>
      <div className="small-container">
    {/* Sidebar */}
    <div className="small-sidebar">
      {/* Top wave */}
      <div className="sidebar-wave-top"></div>
      <ul>
      <li>
  <a href="presidents_message">President's Message</a>
</li>
        <li><a href="#">Annual Report</a></li>
        <li><a href="interest">Interest Rate</a></li>
        <li><a href="loans">Loans</a></li>
        <li><a href="awards">Awards</a></li>
      </ul>
      {/* Bottom wave */}
      <div className="sidebar-wave"></div>
    </div>

    {/* Content Section */}
    <div className="small-content">
      <div className="image-wrapper">
      <img src={service1} alt="About Us" style={{ width: "1000px", height: "340px", objectFit: "cover" }} />

       
      </div>
    </div>
    
  </div>
  <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.header}>Our Services</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <span style={styles.bulletIcon}>✔️</span>
            Various deposit schemes at attractive interest rates.
          </li>
          <li style={styles.listItem}>
            <span style={styles.bulletIcon}>✔️</span>
            Various loan schemes at competitive interest rates.
          </li>
          <li style={styles.listItem}>
            <span style={styles.bulletIcon}>✔️</span>
            E-Stamping facility.
          </li>
          <li style={styles.listItem}>
            <span style={styles.bulletIcon}>✔️</span>
            PAN card facility.
          </li>
          <li style={styles.listItem}>
            <span style={styles.bulletIcon}>✔️</span>
            Interest for senior citizens, physically challenged, widows,
            soldiers, and institutional deposits (added interest will be
            given).
          </li>
          <li style={styles.listItem}>
            <span style={styles.bulletIcon}>✔️</span>
            Safe locker facility.
          </li>
        </ul>
      </div>
    </div>
    </div>
  )
}
