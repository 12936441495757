import React from 'react';
import logo from "../src/assets/Saraswathi.png";

const styles = {
  header: {
    width: "100%",
    backgroundColor: "#b366b3",
    color: "white",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    position: "fixed",  // Make the header fixed
    top: 0,             // Stick it to the top of the viewport
    zIndex: 1000, 
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    maxWidth: "1000px", // Example max-width to prevent overly wide layout
    margin: "0 auto",
    
     // Center align the navbar
  },
  logoContainer: {
    flexShrink: 0,
  },
  logo: {
    height: "auto",
    maxHeight: "80px", // Adjust maximum logo height
    width: "auto",
    maxWidth: "100px", // Adjust maximum logo width
  },
  navLinks: {
    display: "flex",
    gap: "15px",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "15px",
    fontWeight: "bold",
    padding: "5px 10px",
    transition: "background-color 0.3s",
  },
  linkHover: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  centeredTitle: {
    backgroundColor: "#ffffff",
    color: "#800080",
    textAlign: "center",
    padding: "6px 0",
    fontWeight: "bold",
    fontSize: "5px",
  },
};

export default function Navbar() {
  return (
    <div>
      <header style={styles.header}>
        <div style={styles.navbar}>
          {/* Logo */}
          <div style={styles.logoContainer}>
            <img
              src={logo}
              alt="Logo"
              style={styles.logo}
            />
          </div>

          {/* Navigation Links */}
          <nav style={styles.navLinks}>
            <a href="/" style={styles.link}>Home</a>
            <a href="about1" style={styles.link}>About Us</a>
            <a href="directors" style={styles.link}>Organization</a>
            <a href="financial1" style={styles.link}>Financial Details</a>
            <a href="services1" style={styles.link}>Our Services</a>
            <a href="subsidiary1" style={styles.link}>Subsidiary</a>
            <a href="#gallery" style={styles.link}>Gallery</a>
            <a href="contact1" style={styles.link}>Contact Us</a>
          </nav>
        </div>

        {/* Centered Title */}
        <div style={styles.centeredTitle}>
          <h3>Shri Saraswathi Credit Souharda Sahakari Sangha LTD. Puttur, DK</h3>
        </div>
      </header>
      
    </div>
  );
}
